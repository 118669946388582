import React, { Component } from "react";
import Slider from "react-slick";
import logo from "./logo-white.png";
import "./scss/js/slick.scss";
import "./scss/components/logo-overlay.scss";

const images = {
  wedding: [
    require("./corporate/corporate-1.jpg"),
    require("./corporate/corporate-2.jpg"),
    require("./corporate/corporate-3.jpg"),
    require("./corporate/corporate-4.jpg"),
    require("./corporate/corporate-5.jpg"),
    require("./corporate/corporate-6.jpg"),
    require("./corporate/corporate-7.jpg"),
    require("./corporate/corporate-8.jpg"),
    require("./corporate/corporate-9.jpg"),
    require("./corporate/corporate-10.jpg"),
    require("./corporate/corporate-11.jpg"),
    require("./corporate/corporate-12.jpg"),
    require("./corporate/corporate-13.jpg"),
    require("./corporate/corporate-14.jpg"),
    require("./corporate/corporate-15.jpg"),
    require("./corporate/corporate-16.jpg"),
    require("./corporate/corporate-17.jpg"),
    require("./corporate/corporate-18.jpg"),
    require("./corporate/corporate-19.jpg"),
    require("./corporate/corporate-20.jpg"),
    require("./corporate/corporate-21.jpg"),
    require("./corporate/corporate-22.jpg"),
    require("./corporate/corporate-23.jpg"),
    require("./corporate/corporate-24.jpg"),
    require("./corporate/corporate-25.jpg"),
    require("./corporate/corporate-26.jpg"),
    require("./corporate/corporate-27.jpg"),
    require("./corporate/corporate-28.jpg"),
    require("./corporate/corporate-29.jpg"),
    require("./corporate/corporate-30.jpg"),
    require("./corporate/corporate-31.jpg"),
    require("./corporate/corporate-32.jpg"),
    require("./corporate/corporate-33.jpg"),
    require("./corporate/corporate-34.jpg"),
    require("./corporate/corporate-35.jpg"),
    require("./corporate/corporate-36.jpg"),
    require("./corporate/corporate-37.jpg"),
  ],
};

export default class SimpleSlider extends Component {
  settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  renderSlides = () => {
    return images.wedding.map((image, index) => {
      const slideStyle = {
        backgroundPosition: "center 50%",
        backgroundSize: "cover",
        height: "calc(100vh - 70px)",
        width: "100%",
        backgroundImage: "url(" + image + ")",
      };

      return (
        <div key={index}>
          <div style={slideStyle} />
        </div>
      );
    });
  };

  render() {
    return (
      <div className="dis dis-1">
        <img className="logo-overlay" src={logo} alt="logo" />
        <Slider {...this.settings}>{this.renderSlides()}</Slider>
      </div>
    );
  }
}
