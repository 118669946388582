import React, { Component } from "react";
import Slider from "react-slick";
import logo from "./logo-white.png";
import "./scss/js/slick.scss";
import "./scss/components/logo-overlay.scss";

const images = {
  wedding: [
    require("./wedding/wedding-1.jpg"),
    require("./wedding/wedding-2.jpg"),
    require("./wedding/wedding-3.jpg"),
    require("./wedding/wedding-4.jpg"),
    require("./wedding/wedding-5.jpg"),
    require("./wedding/wedding-6.jpg"),
    require("./wedding/wedding-7.jpg"),
    require("./wedding/wedding-8.jpg"),
    require("./wedding/wedding-9.jpg"),
    require("./wedding/wedding-10.jpg"),
    require("./wedding/wedding-11.jpg"),
    require("./wedding/wedding-12.jpg"),
    require("./wedding/wedding-13.jpg"),
    require("./wedding/wedding-14.jpg"),
    require("./wedding/wedding-15.jpg"),
    require("./wedding/wedding-16.jpg"),
    require("./wedding/wedding-17.jpg"),
    require("./wedding/wedding-18.jpg"),
    require("./wedding/wedding-19.jpg"),
    require("./wedding/wedding-20.jpg"),
    require("./wedding/wedding-21.jpg"),
    require("./wedding/wedding-22.jpg"),
    require("./wedding/wedding-23.jpg"),
    require("./wedding/wedding-24.jpg"),
    require("./wedding/wedding-25.jpg"),
    require("./wedding/wedding-26.jpg"),
    require("./wedding/wedding-27.jpg"),
    require("./wedding/wedding-28.jpg"),
    require("./wedding/wedding-29.jpg"),
    require("./wedding/wedding-30.jpg"),
    require("./wedding/wedding-31.jpg"),
    require("./wedding/wedding-32.jpg"),
    require("./wedding/wedding-33.jpg"),
    require("./wedding/wedding-34.jpg"),
    require("./wedding/wedding-35.jpg"),
    require("./wedding/wedding-36.jpg"),
    require("./wedding/wedding-37.jpg"),
    require("./wedding/wedding-38.jpg"),
    require("./wedding/wedding-39.jpg"),
    require("./wedding/wedding-40.jpg"),
    require("./wedding/wedding-41.jpg"),
    require("./wedding/wedding-42.jpg"),
    require("./wedding/wedding-43.jpg"),
    require("./wedding/wedding-44.jpg"),
    require("./wedding/wedding-45.jpg"),
    require("./wedding/wedding-46.jpg"),
    require("./wedding/wedding-47.jpg"),
    require("./wedding/wedding-48.jpg"),
    require("./wedding/wedding-49.jpg"),
    require("./wedding/wedding-50.jpg"),
    require("./wedding/wedding-51.jpg"),
    require("./wedding/wedding-52.jpg"),
    require("./wedding/wedding-53.jpg"),
    require("./wedding/wedding-54.jpg"),
    require("./wedding/wedding-55.jpg"),
    require("./wedding/wedding-56.jpg"),
    require("./wedding/wedding-57.jpg"),
    require("./wedding/wedding-58.jpg"),
    require("./wedding/wedding-59.jpg"),
    require("./wedding/wedding-60.jpg"),
    require("./wedding/wedding-61.jpg"),
    require("./wedding/wedding-62.jpg"),
    require("./wedding/wedding-63.jpg"),
    require("./wedding/wedding-64.jpg"),
    require("./wedding/wedding-65.jpg"),
    require("./wedding/wedding-66.jpg"),
    require("./wedding/wedding-67.jpg"),
    require("./wedding/wedding-68.jpg"),
    require("./wedding/wedding-69.jpg"),
    require("./wedding/wedding-70.jpg"),
    require("./wedding/wedding-71.jpg"),
    require("./wedding/wedding-72.jpg"),
    require("./wedding/wedding-73.jpg"),
    require("./wedding/wedding-74.jpg"),
    require("./wedding/wedding-75.jpg"),
    require("./wedding/wedding-76.jpg"),
    require("./wedding/wedding-77.jpg"),
    require("./wedding/wedding-78.jpg"),
    require("./wedding/wedding-79.jpg"),
    require("./wedding/wedding-80.jpg"),
    require("./wedding/wedding-81.jpg"),
    require("./wedding/wedding-82.jpg"),
    require("./wedding/wedding-83.jpg"),
    require("./wedding/wedding-84.jpg"),
    require("./wedding/wedding-85.jpg"),
    require("./wedding/wedding-86.jpg"),
    require("./wedding/wedding-87.jpg"),
    require("./wedding/wedding-88.jpg"),
    require("./wedding/wedding-89.jpg"),
    require("./wedding/wedding-90.jpg"),
    require("./wedding/wedding-91.jpg"),
    require("./wedding/wedding-92.jpg"),
    require("./wedding/wedding-93.jpg"),
    require("./wedding/wedding-94.jpg"),
    require("./wedding/wedding-95.jpg"),
    require("./wedding/wedding-96.jpg"),
    require("./wedding/wedding-97.jpg"),
    require("./wedding/wedding-98.jpg"),
    require("./wedding/wedding-99.jpg"),
    require("./wedding/wedding-101.jpg"),
    require("./wedding/wedding-102.jpg"),
    require("./wedding/wedding-103.jpg"),
    require("./wedding/wedding-104.jpg"),
    require("./wedding/wedding-105.jpg"),
    require("./wedding/wedding-106.jpg"),
    require("./wedding/wedding-107.jpg"),
    require("./wedding/wedding-108.jpg"),
    require("./wedding/wedding-109.jpg"),
    require("./wedding/wedding-110.jpg"),
    require("./wedding/wedding-111.jpg"),
    require("./wedding/wedding-112.jpg"),
    require("./wedding/wedding-113.jpg"),
    require("./wedding/wedding-114.jpg"),
    require("./wedding/wedding-115.jpg"),
    require("./wedding/wedding-116.jpg"),
    require("./wedding/wedding-117.jpg"),
    require("./wedding/wedding-118.jpg"),
    require("./wedding/wedding-119.jpg"),
    require("./wedding/wedding-120.jpg"),
    require("./wedding/wedding-121.jpg"),
    require("./wedding/wedding-122.jpg"),
    require("./wedding/wedding-123.jpg"),
    require("./wedding/wedding-124.jpg"),
  ],
};

export default class SimpleSlider extends Component {
  settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  renderSlides = () => {
    return images.wedding.map((image, index) => {
      const slideStyle = {
        backgroundPosition: "center 50%",
        backgroundSize: "cover",
        height: "calc(100vh - 70px)",
        width: "100%",
        backgroundImage: "url(" + image + ")",
      };

      return (
        <div key={index}>
          <div style={slideStyle} />
        </div>
      );
    });
  };

  render() {
    return (
      <div className="dis dis-1">
        <img className="logo-overlay" src={logo} alt="logo" />
        <Slider {...this.settings}>{this.renderSlides()}</Slider>
      </div>
    );
  }
}
