import React from "react";
import { ScrollContext } from "react-router-scroll-4";
import "./scss/components/form.scss";
import "./scss/components/btn.scss";
import "./scss/components/success.scss";
import divider from "./divider.png";
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      name: "",
      tel: "",
      email: "",
      sent: false
    };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTel = this.handleChangeTel.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  handleChangeTel(event) {
    this.setState({ tel: event.target.value });
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeMessage(event) {
    this.setState({ message: event.target.value });
  }

  handleSubmit(event) {
    const templateId = "taf";
    this.handleSuccess();

    this.sendFeedback(templateId, {
      name: this.state.name,
      tel: this.state.tel,
      email: this.state.email,
      message: this.state.message,
      from_name: this.state.name,
      reply_to: this.state.email
    });
  }

  handleSuccess() {
    console.log("success");
    this.setState({ sent: true });
  }

  sendFeedback(templateId, variables) {
    window.emailjs
      .send("gmail", templateId, variables)
      .then(res => {
        console.log("Email successfully sent!");
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err =>
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        )
      );
  }

  render() {
    return (
      <ScrollContext>
        <div className="page">
          <h1 className="heading">Contact</h1>
         
          <p>
            <a href="mailto:hello@theancoatsflorist.co.uk">
              hello@theancoatsflorist.co.uk
            </a>
          </p>
          <p>
            <a href="tel:+447871514621">+44 (0)7871 514621</a>
          </p>

          <form
            className={
              this.state.sent ? "form form--page hide" : "form form--page"
            }
          >
            <div>
              <input
                className="form__input"
                placeholder="Name"
                name="name"
                type="text"
                onChange={this.handleChangeName}
                value={this.state.name}
              />
            </div>
            <div>
              <input
                className="form__input"
                placeholder="Phone"
                name="tel"
                type="tel"
                onChange={this.handleChangeTel}
                value={this.state.tel}
              />
            </div>
            <div>
              <input
                className="form__input"
                placeholder="Email"
                name="email"
                type="email"
                onChange={this.handleChangeEmail}
                value={this.state.email}
              />
            </div>
            <div>
              <textarea
                className="form__input"
                placeholder="Message"
                id="test-mailing"
                name="test-mailing"
                onChange={this.handleChangeMessage}
                required
                value={this.state.message}
                style={{ width: "100%", height: "150px" }}
              />
            </div>
            <input
              type="button"
              value="Send"
              className="btn btn--submit"
              onClick={this.handleSubmit}
            />
          </form>
          <div className={this.state.sent ? "success" : "success hide"}>
            <p>Thanks, we'll be in touch</p>
          </div>
        </div>
      </ScrollContext>
    );
  }
}
export default Contact;
