import React from "react";
import Slider from "react-slick";
import image1 from "./h1.jpg";
import image2 from "./h2.jpg";
import image3 from "./h3.jpeg";
import image4 from "./h4.jpg";


import image8 from "./h8.jpg";

import image10 from "./h10.jpg";
import image11 from "./h11.jpg";

import logo from "./logo-white.png";
import "./scss/js/slick.scss";
import "./scss/components/logo-overlay.scss";

class SimpleSlider extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    var h1 = {
      backgroundPosition: "center 50%",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      height: "calc(100vh - 70px)",
      width: "100%",
      backgroundImage: "url(" + image1 + ")"
    };
    var h2 = {
      backgroundPosition: "center 50%",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      height: "calc(100vh - 70px)",
      width: "100%",
      backgroundImage: "url(" + image2 + ")"
    };
    var h3 = {
      backgroundPosition: "center 50%",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      height: "calc(100vh - 70px)",
      width: "100%",
      backgroundImage: "url(" + image3 + ")"
    };
    var h4 = {
      backgroundPosition: "center 50%",
      backgroundSize: "cover",
      height: "calc(100vh - 70px)",
      width: "100%",
      backgroundImage: "url(" + image4 + ")"
    };
   
   
    var h8 = {
      backgroundPosition: "center 50%",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      height: "calc(100vh - 70px)",
      width: "100%",
      backgroundImage: "url(" + image8 + ")"
    };

    var h10 = {
      backgroundPosition: "center 50%",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      height: "calc(100vh - 70px)",
      width: "100%",
      backgroundImage: "url(" + image10 + ")"
    };
    var h11 = {
      backgroundPosition: "center 50%",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      height: "calc(100vh - 70px)",
      width: "100%",
      backgroundImage: "url(" + image11 + ")"
    };

    return (
      <div className="dis dis-1">
        <img className="logo-overlay" src={logo} />
        <Slider {...settings}>
          <div>
            <div style={h1} />
          </div>
          <div>
            <div style={h2} />
          </div>
          <div>
            <div style={h3} />
          </div>
          <div>
            <div style={h4} />
          </div>
         
          
          <div>
            <div style={h8} />
          </div>

          <div>
            <div style={h10} />
          </div>
          <div>
            <div style={h11} />
          </div>
        </Slider>
      </div>
    );
  }
}

export default SimpleSlider;
