import React from "react";
import ReactDOM from "react-dom";
import "./scss/base/reset.scss";
import "./scss/base/type.scss";
import "./index.scss";
import "./scss/components/navigation.scss";
import "./scss/components/logo.scss";
import "./scss/components/footer.scss";
import logo from "./logo-black.png";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import Contact from "./contact";
import About from "./about";
import Weddings from "./weddings";
import Seasonal from "./seasonal";
import Corporate from "./corporate";
import * as serviceWorker from "./serviceWorker";

const routing = (
  <Router>
    <div className="dis">
      <div className="bg-component" />
      <Route exact path="/" component={App} />
      <Route path="/weddings" component={Weddings} />
      <Route path="/seasonal" component={Seasonal} />
      <Route path="/corporate" component={Corporate} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <div className="footer">
        <div className="footer__logo-container">
          <img className="footer__logo" src={logo} />
        </div>
        <ul className="navigation">
          <li className="navigation__item">
            <Link className="navigation__link" to="/">
              Portfolio
            </Link>
          </li>
          <li className="navigation__item">
            <Link className="navigation__link" to="/weddings">
              Weddings
            </Link>
          </li>
          <li className="navigation__item">
            <Link className="navigation__link" to="/seasonal">
              Seasonal
            </Link>
          </li>
          <li className="navigation__item">
            <Link className="navigation__link" to="/corporate">
              Corporate
            </Link>
          </li>
          <li className="navigation__item">
            <Link className="navigation__link" to="/about">
              About
            </Link>
          </li>
          <li className="navigation__item">
            <Link className="navigation__link" to="/contact">
              Contact
            </Link>
          </li>
          <li className="navigation__item  navigation__item--desktop">
            <a
              className="navigation__link"
              href="https://www.instagram.com/theancoatsflorist/"
            >
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
