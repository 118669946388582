import React from "react";
import { ScrollContext } from "react-router-scroll-4";
import "./scss/components/page.scss";
import about from "./about.jpg";
import divider from "./divider.png";
import logo from "./logo-black.png";
class About extends React.Component {
  render() {
    return (
      <ScrollContext>
        <div className="page">
          <h1 className="heading">About</h1>
         
          <p>
            Hello. I’m Dan France, the gal holding the scissors here at The
            Ancoats Florist. I always quite fancied the idea of being a florist
            when I was little, but my mum quickly pushed it from my mind by
            reminding me that I unfortunately have hay fever. Nonsense.
          </p>

          <p>
            So, after a half-arsed attempt at an English degree, and a string of
            jobs I endlessly moaned about, my very patient husband asked me a
            big ol’ question - what is it you’d actually love to do? And so here
            I am. Loving every petal, every leaf, every minute, of every day,
            doing something that makes me dead happy. I still feel silly calling
            it a job to be honest!
          </p>

          <p>
            I am as passionate about people as I am about flowers. And wonderful
            service. Because going that extra mile costs nothing but makes all
            the bloody difference.
          </p>

          <p>
            From weddings to workshops; bouquets to business contracts; events
            and beyond… give me a bell.
          </p>

          <p>No rain, no flowers. #floralAF</p>

          <img className="page__logo" src={logo} />

          <img className="page__image" src={about} />
        </div>
      </ScrollContext>
    );
  }
}
export default About;
